const got_i = '1.0.1'
const map_key = 'AIzaSyBIkHVWFfv2YTxMCT52TE4SjhBDNt-wLAI'; // Latest key updated for google map api
setTimeout(function () {
  if (dashboardExtObj) {
    dashboardExtObj.loadScript('/assets/ckeditor/ckeditor.js?t=' + got_i, null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
    dashboardExtObj.loadScript('//maps.googleapis.com/maps/api/js?libraries=places&key=' + map_key + '&t=' + got_i, null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
    dashboardExtObj.loadScript('//www.gstatic.com/charts/loader.js?t=' + got_i, null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
    dashboardExtObj.loadScript('/assets/scripts/ads.js?t=' + got_i, null, null, null, adCanLoad, dashboardExtObj.hasError);
    dashboardExtObj.loadScript('/assets/scripts/dom-to-image.js?t=' + got_i, null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
    dashboardExtObj.loadScriptSync('//api.mapbox.com/mapbox-gl-js/v2.9.2/mapbox-gl.js', null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
    dashboardExtObj.loadScriptSync('//unpkg.com/three@0.126.0/build/three.min.js', null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
    dashboardExtObj.loadScriptSync('https://unpkg.com/@turf/turf@6/turf.min.js', null, null, null, dashboardExtObj.hasLoaded, dashboardExtObj.hasError);
  }
}, 5000);
