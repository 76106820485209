var adCanLoad = function () {
  console.log("Checking if ad-blocker is enabled??");
  if (window.canRunAds !== undefined) {
    setTimeout(function () {
      dashboardExtObj.loadScript(
        "/assets/scripts/js-hs-scripts-com-5010372.js",
        null,
        null,
        null,
        dashboardExtObj.hasLoaded,
        dashboardExtObj.hasError
      );
    }, 5000);
  } else {
    // adblocker in browser detected, show fallback if required
    console.log("Ad-blocker is enabled!!!");
  }
};
var dashboardExtObj = (function (dashObj) {
  return {
    oldConsoleLogger: null,
    db: null,
    dbNames: {
      locationObjectArray: 1,
      locationWrap: 1,
      locationMap: 1,
      iotLocationList: 1,
      webexRoomsWrap:2
    },
    loadScript: function (
      url,
      integrity,
      type,
      noModule,
      callbackLoaded,
      callbackError
    ) {
      // adding the script tag to the head as suggested before
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      if (type) {
        script.type = type;
      } else {
        script.type = "text/javascript";
      }
      script.defer = true;
      script.async = true;
      script.src = url;
      if (noModule) {
        script.setAttribute("nomodule", "");
      }
      // if (integrity) {
      //     script.setAttribute("integrity", integrity);
      //     script.setAttribute("crossorigin", "anonymous");
      // }
      // then bind the event to the callback function
      if (typeof callbackLoaded == "function") {
        script.onload = callbackLoaded;
      }
      if (typeof callbackError == "function") {
        script.onerror = callbackError;
      }
      // load the script in html
      head.appendChild(script);
    },
    loadScriptSync: function (
      url,
      integrity,
      type,
      noModule,
      callbackLoaded,
      callbackError
    ) {
      // adding the script tag to the head as suggested before
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      if (type) {
        script.type = type;
      } else {
        script.type = "text/javascript";
      }
      script.src = url;
      if (noModule) {
        script.setAttribute("nomodule", "");
      }
      // if (integrity) {
      //     script.setAttribute("integrity", integrity);
      //     script.setAttribute("crossorigin", "anonymous");
      // }
      // then bind the event to the callback function
      if (typeof callbackLoaded == "function") {
        script.onload = callbackLoaded;
        if (
          script.src &&
          script.src ==
          "https://unpkg.com/three@0.126.0/build/three.min.js"
        ) {
          setTimeout(() => {
            this.loadScriptSync(
              "//unpkg.com/three@0.126.0/examples/js/loaders/GLTFLoader.js",
              null,
              null,
              null,
              dashboardExtObj.hasLoaded,
              dashboardExtObj.hasError
            );
          }, 2000);
        } else if (
          script.src &&
          script.src ==
          "https://unpkg.com/three@0.126.0/examples/js/loaders/GLTFLoader.js"
        ) {
          const searchParams = new URLSearchParams(
            window.location.search
          );
          console.log(searchParams.get("domain"));
          const hostDomain = searchParams.get("domain") != null ?
            searchParams.get("domain") :
            window.location.host;
          const domain_host = hostDomain.split(".");
          const endpoint_url = domain_host[domain_host.length - 1];
          const hostSplit = window.location.host.split(".");
          let setup = "dnaspaces";
          if (hostSplit.length) {
            if (hostSplit.length > 2) {
              setup = hostSplit[1];
            } else {
              setup = hostSplit[0]
            }
          }
          console.log("setup", setup, "endpoint_url", endpoint_url);
          const richMapUrl =
            "//rms." +
            setup +
            "." +
            endpoint_url +
            "/js/rich-maps-0.1.5.js";
          this.loadScriptSync(
            richMapUrl,
            null,
            null,
            null,
            dashboardExtObj.hasLoaded,
            dashboardExtObj.hasError
          );
          // to add rich-maps-controls js
          console.log("setup", setup, "endpoint_url", endpoint_url);
          const richMapControlsUrl =
            "//rms." +
            setup +
            "." +
            endpoint_url +
            "/js/rich-maps-controls-0.0.1.js";
          this.loadScriptSync(
            richMapControlsUrl,
            null,
            null,
            null,
            dashboardExtObj.hasLoaded,
            dashboardExtObj.hasError
          );
          // to add mapbox-gl js
          // loading tile map SDK
          const networkMapUrl =
            "//rms." +
            setup +
            "." +
            endpoint_url +
            "/js/network-maps-1.0.js";
          console.log("networkMapUrl ::", networkMapUrl);
          this.loadScriptSync(
            networkMapUrl,
            null,
            null,
            null,
            dashboardExtObj.hasLoaded,
            dashboardExtObj.hasError
          );


          const mapBoxUrl =
            "//rms." +
            setup +
            "." +
            endpoint_url +
            "/js/mapbox-gl.js";
          if (typeof mapboxgl === 'undefined') {
            this.loadScriptSync(
              mapBoxUrl,
              null,
              null,
              null,
              dashboardExtObj.hasLoaded,
              dashboardExtObj.hasError
            );
          }
        }
      }
      if (typeof callbackError == "function") {
        script.onerror = callbackError;
      }
      // load the script in html
      head.appendChild(script);
    },
    hasLoaded: function (evt) {
      console.log(
        "Loaded the script with response to callback function",
        evt
      );
    },
    hasError: function (evt) {
      console.log(
        "Could not load the script with response to callback function",
        evt
      );
      // if (/ads\.js/g.test(evt.currentTarget.src)) {
      //   adCanLoad()
      // } else {
      //   let numberOfErrorReloads =
      //     localStorage.getItem('numberOfErrorReloads') || 0
      //   console.log('numberOfErrorReloads', numberOfErrorReloads)
      //   if (numberOfErrorReloads < 10) {
      //     numberOfErrorReloads++
      //     localStorage.setItem('numberOfErrorReloads', numberOfErrorReloads)
      //     setTimeout(function () {
      //       location.reload()
      //     }, 5000)
      //   } else {
      //     console.log(
      //       'Page was refreshed multiple times but error script did not go away.'
      //     )
      //     localStorage.removeItem('numberOfErrorReloads')
      //     window.location.reload()
      //   }
      // }
    },
    initPendo: function (obj) {
      var pendo = "pendo";
      window[pendo].initialize({
        visitor: {
          id: obj.userId + "_" + obj.baseUrl
        },
        account: {
          id: obj.tenantId + "_" + obj.baseUrl,
          licenseType: obj.licenseType,
          customerName: obj.customerName,
          accountType: obj.accountType,
          licenseCount: obj.licenseCount,
          licenseDuration: obj.licenseDuration,
          startDate: obj.startDate,
          customerAccountNo: obj.customerAccountNo,
          totalAps: obj.totalAps,
          consumedAps: obj.consumedAps,
          productEdition: obj.productEdition
        }
      });
    },
    hubspotFn: function (pushObj) {
      console.log("_hsq method called", pushObj);
      _hsq.push([pushObj.method, pushObj.value]);
    },
    enableLogger: function () {
      if (this.oldConsoleLogger == null) return;
      window["console"]["log"] = this.oldConsoleLogger;
    },
    disableLogger: function () {
      this.oldConsoleLogger = console.log;
      window["console"]["log"] = function () {};
    },
    initIndexedDB: function () {
      //prefixes of implementation that we want to test
      window.indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB;

      //prefixes of window.IDB objects
      window.IDBTransaction =
        window.IDBTransaction ||
        window.webkitIDBTransaction ||
        window.msIDBTransaction;
      window.IDBKeyRange =
        window.IDBKeyRange ||
        window.webkitIDBKeyRange ||
        window.msIDBKeyRange;

      if (!window.indexedDB) {
        console.log("Your browser doesn't support a stable version of IndexedDB.")
      }
      var that = this;
      // Let us open our database
      var dbVersion = localStorage.getItem('dbVersion'); //|| 3
      var DBOpenRequest = dbVersion ? window.indexedDB.open("dashboardDB", dbVersion) : window.indexedDB.open("dashboardDB");
      DBOpenRequest.onerror = function (event) {
        console.log("error: Database not initialised.", event);
        DBOpenRequest = window.indexedDB.open("dashboardDB");
        // localStorage.setItem('dbVersion', that.db.version);
      };

      DBOpenRequest.onsuccess = function (event) {
        that.db = DBOpenRequest.result;
        console.log("success: Database initialised. ", that.db);
        localStorage.setItem('dbVersion', that.db.version);
        // Clear all the data form the object store
        for (var key in that.dbNames) {
          if (that.dbNames.hasOwnProperty(key)) {
            console.log(key, that.dbNames[key]);
            if (key !== "locationMap") {
              that.indexedDbClearData(key);
            }
          }
        }
      };

      DBOpenRequest.onupgradeneeded = function (event) {
        that.db = event.target.result;
        console.log(
          "success: Database already initialised. upgrade required " +
          JSON.stringify(that.db)
        );
        if (event.oldVersion < event.newVersion) {
          for (let key in that.dbNames) {
            // if (that.dbNames.hasOwnProperty(key)) {
            //   console.log(key, that.dbNames[key]);
            if (!that.db.objectStoreNames.contains(key)) {
              that.db.createObjectStore(key, {
                autoIncrement: true
              });
              localStorage.setItem('dbVersion', dbVersion++);
            }
            // }
          }
        }
      };
    },
    indexedDbRead: function (storeName) {
      var that = this;
      return new Promise(function (resolve, reject) {
        console.log("Transaction indexedDbRead.", storeName);
        that.dbNames[storeName] = 1;
        var transaction = that.db.transaction([storeName]);
        var objectStore = transaction.objectStore(storeName);
        var request = objectStore.getAll();

        request.onerror = function (event) {
          console.log(
            "Unable to retrieve data from database!",
            storeName,
            itemKey,
            event
          );
          // throw "Unable to retrieve data from database";
          resolve(null);
        };

        request.onsuccess = function (event) {
          // Do something with the request.result!
          if (request.result) {
            console.log("Data found : ");
            resolve(request.result[0]);
          } else {
            console.log("Data couldn't be found in your database!");
            resolve(null);
          }
        };
      });
    },
    indexedDbClearData: function (storeName) {
      var that = this;
      return new Promise(function (resolve, reject) {
        console.log("Transaction indexedDbClearData.", storeName);
        that.dbNames[storeName] = 1;
        // open a read/write db transaction, ready for clearing the data
        var transaction = that.db.transaction([storeName], "readwrite");

        // report on the success of the transaction completing, when everything is done
        transaction.oncomplete = function (event) {
          console.log("Transaction completed.", storeName);
        };

        transaction.onerror = function (event) {
          console.log(
            "Transaction not opened due to error: ",
            transaction.error,
            storeName
          );
          resolve(null);
        };

        // create an object store on the transaction
        var objectStore = transaction.objectStore(storeName);

        // Make a request to clear all the data out of the object store
        var objectStoreRequest = objectStore.clear();

        objectStoreRequest.onsuccess = function (event) {
          // report the success of our request
          console.log("Request successful. ", storeName);
          resolve(true);
        };
      });
    },
    indexedDbRemove: function (storeName, itemKey) {
      let that = this;
      return new Promise(function (resolve, reject) {
        console.log("Transaction indexedDbRemove.", storeName, itemKey);
        that.dbNames[storeName] = 1;
        var request = that.db
          .transaction([storeName], "readwrite")
          .objectStore(storeName)
          .delete(itemKey);

        request.onsuccess = function (event) {
          resolve(true);
        };
        request.onerror = function (event) {
          resolve(false);
        };
      });
    },
    indexedDbAdd: function (storeName, itemValue) {
      let that = this;
      return new Promise(function (resolve, reject) {
        console.log("Transaction indexedDbAdd.", storeName);
        if (that.dbNames == null) return;
        that.dbNames[storeName] = 1;
        if (!itemValue) return;
        // clear first
        that.indexedDbClearData(storeName);
        // add
        var request = that.db
          .transaction([storeName], "readwrite")
          .objectStore(storeName)
          .add(itemValue);

        request.onsuccess = function (event) {
          console.log("Added to your database.");
          resolve(true);
        };

        request.onerror = function (event) {
          console.log("Unable to add data.");
          resolve(false);
        };
      });
    },
    indexedDbPut: function (storeName, itemValue, key) {
      let that = this;
      return new Promise(function (resolve, reject) {
        // console.log("Transaction indexedDb1Add.", storeName);
        if (that.dbNames == null) return;
        that.dbNames[storeName] = 1;
        if (!itemValue) return;
        // add
        var request = that.db
          .transaction([storeName], "readwrite")
          .objectStore(storeName)
          .put(itemValue, key);

        request.onsuccess = function (event) {
          // console.log("Added to your database.");
          resolve(true);
        };

        request.onerror = function (event) {
          // console.log("Unable to add data.");
          resolve(false);
        };
      });
    },
    indexedDbReadAll: function (storeName) {
      let that = this;
      return new Promise(function (resolve, reject) {
        // console.log("Transaction indexedDbRead.", storeName);
        that.dbNames[storeName] = 1;
        var transaction = that.db.transaction([storeName]);
        var objectStore = transaction.objectStore(storeName);
        var request = objectStore.getAll();

        request.onerror = function (event) {
          // console.log("Unable to retrieve data from database!", storeName, itemKey, event);
          // throw "Unable to retrieve data from database";
          resolve(null);
        };

        request.onsuccess = function (event) {
          // Do something with the request.result!
          if (request.result) {
            // console.log("Data found : ");
            resolve(request.result);
          } else {
            // console.log("Data couldn't be found in your database!");
            resolve(null);
          }
        };
      });
    },
    indexedDbReadRow: function (storeName, key) {
      let that = this;
      return new Promise(function (resolve, reject) {
        // console.log("Transaction indexedDbRead.", storeName, key);
        that.dbNames[storeName] = 1;
        var transaction = that.db.transaction([storeName]);
        var objectStore = transaction.objectStore(storeName);
        var request = objectStore.get(key);

        request.onerror = function (event) {
          // console.log(event);
          // console.log("Unable to retrieve data from database!", storeName, itemKey, event);
          // throw "Unable to retrieve data from database";
          resolve(null);
        };

        request.onsuccess = function (event) {
          // Do something with the request.result!
          // console.log(event, request.result);
          if (request.result) {
            // console.log("Data found : ");
            resolve(request.result);
          } else {
            // console.log("Data couldn't be found in your database!");
            resolve(null);
          }
        };
      });
    }
  };
})(dashboardExtObj || {});

dashboardExtObj.initIndexedDB();
if (!/(local|sandbox)/.test(document.location.host)) {
  dashboardExtObj.disableLogger();
}

console.log("performance.navigation.type", performance.navigation.type);
if (performance.navigation.type == 2) {
  location.reload(true);
}

try {
  if (
    top.location.hostname != self.location.hostname ||
    window !== window.parent
  )
    throw 1;
} catch (e) {
  top.location.href = self.location.href;
}

// For HubSpot Tracking code
var _hsq = (window._hsq = window._hsq || []);

//<!--##SCRIPT##-->
